<template>
  <div class="noselect margins3">
    <section class="modalTurn">
      <div class="modalTurn-content">
        <div class="modalWin">
          <h3>Смена пароля</h3>
          <br />
          <div class="flexR center">Введите текущий пароль</div>
          <div class="flexR center">
            <input type="password" v-model="pass_current" ref="pass_current" />
            &nbsp;
            <span class="pointer" @click="eyeClick('pass_current')">{{ "\u{1F441}" }}</span>
          </div>
          <br />
          <div>Введите новый пароль</div>
          <div class="flexR center">
            <input type="password" v-model="pass_new" ref="pass_new" />
            &nbsp;
            <span class="pointer" @click="eyeClick('pass_new')">{{ "\u{1F441}" }}</span>
          </div>
          <br />
          <div class="err">&nbsp;{{ msg }}</div>
          <br />
          <div class="flexR" style="justify-content: space-evenly">
            <button v-on:click="windowClose()" class="btn cancel" ref="cancel">Отмена</button>
            <button v-on:click="Apply()" class="btn" ref="apply">Применить</button>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { request } from "@/components-js/requestSrv";
export default {
  components: {},

  data() {
    return {
      pass_current: "",
      pass_new: "",
      msg: "",
    };
  },

  methods: {
    windowClose() {
      this.$router.go(-1);
    },

    async Apply() {
      this.buttonsDisable();
      const res = await this.passChecks();
      if (!res) {
        this.buttonsEnable();
        return;
      }
      this.windowClose();
      this.buttonsEnable();
    },

    buttonsDisable() {
      this.$refs.cancel.disabled = true;
      this.$refs.apply.disabled = true;
    },

    buttonsEnable() {
      this.$refs.cancel.disabled = false;
      this.$refs.apply.disabled = false;
    },

    async passChecks() {
      const n = 4;
      if (this.pass_new.length < 4) {
        this.msg = `Длина пароля не менее ${n} символов.`;
        setTimeout(() => (this.msg = ""), 1000);
        return false;
      }

      let res = await request("/api/user/passcheck", "POST", {
        pass_current: this.pass_current,
        id: this.$root.user.uid,
      });

      if (!res.res) {
        this.msg = `Неверный текущй пароль`;
        setTimeout(() => (this.msg = ""), 1000);
        return false;
      }

      // change pass at least
      res = await request("/api/user/passchange", "POST", {
        pass_new: this.pass_new,
        id: this.$root.user.uid,
      });

      if (!res) {
        this.msg = "Ошибка изменения пароля";
        setTimeout(() => (this.msg = ""), 1000);
        return false;
      }

      return true;
    },

    eyeClick(ref) {
      const type1 = "text",
        type2 = "password";
      if (this.$refs[ref].type == type1) this.$refs[ref].type = type2;
      else this.$refs[ref].type = type1;
    },
  },
};
</script>
